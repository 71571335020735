import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'gatsby'
import { rhythm, scale } from '../utils/typography'

const Article = ({ articles }) => {
  return (
    <div>
      {articles.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article key={node.fields.slug}>
            <header>
              <h2
                style={{
                  marginBottom: rhythm(1 / 4)
                }}
              >
                <Link style={{
                  color: '#1b262c',
                  textDecoration: 'none'
                }} to={node.fields.slug}>
                  {title}
                </Link>
              </h2>
              <small style={{
                ...scale(-1 / 5),
                display: 'block',
                marginBottom: rhythm(1)
              }}>
                {node.frontmatter.date}
              </small>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt
                }}
              />
              <Link to={node.fields.slug}>
                Read Article →
              </Link>
            </section>
          </article>
        )
      })}
    </div>
  )
}

Article.propTypes = {
  articles: PropTypes.array
}

export default Article

import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Articles from '../components/Articles'
import SEO from '../components/SEO'

const Index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const articles = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO />
      <Articles articles={articles} />
    </Layout>
  )
}

Index.propTypes = {
  data: PropTypes.any,
  location: PropTypes.any
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
